body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: monospace, serif;
    color: #003a4f;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu {
    font-family: monospace, serif;
    display: flex;
    font-size: 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.left-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 45px;
    background: rgba(0, 58, 79, 0.26);
}

.right-panel {
    background: rgba(142, 162, 162, 0.38);
    font-family: monospace, serif;
    font-size: 18px;
}

.right-panel, .right-panel p {
    font-weight: bold;
}

.panel {
    height: 100vh;
    padding: 80px 80px 0 80px;
}

.right-panel a {
    color: #003a4f;
    font-weight: bolder;
    text-decoration: underline;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-anchor-wrapper .ant-anchor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
}


:where(.css-dev-only-do-not-override-1rqnfsa).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before {
    border-inline-start: none;
    border-inline-end: 2px !important;
    border-inline-end-color: red !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-anchor-wrapper .ant-anchor .ant-anchor-link-active > .ant-anchor-link-title {
    color: #003a4f;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
    background-color: #003a4f;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink.ant-anchor-ink-visible {
    display: none;
}

.who-am-i {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: monospace;
}

.me-photo {
    padding-right: 25px;
}

.me-content {
    font-family: monospace;
    font-weight: lighter;
}
